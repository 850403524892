import React from "react";
import { Campaignform } from "./Forms";
const Campaign = () => {
  
  return (
    <div className="m-5">
        <div className="row d-flex justify-content-center">
            <div className="col-9 ">
            <Campaignform/>
            </div>
        </div>

    </div>
  );
};

export default Campaign;
