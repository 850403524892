import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { Trans } from "react-i18next";
import { MdDashboard } from "react-icons/md";
import { TiNews } from "react-icons/ti";
import { IoMdAddCircle } from "react-icons/io";
import { RiEdgeNewFill } from "react-icons/ri";
import { BiSolidReport } from "react-icons/bi";
import { MdCampaign } from "react-icons/md";
import { FaChartPie } from "react-icons/fa";
import { FcDataConfiguration } from "react-icons/fc";
import { FaSitemap } from "react-icons/fa6";
import { RiPagesLine } from "react-icons/ri";
import { CgInternal } from "react-icons/cg";
import { GrDocumentTxt } from "react-icons/gr";
import { IoShareSocialSharp } from "react-icons/io5";

const style = {
  color: "#FFF",
};
const activeStyle = {
  color: "#FFF",
  fontWeight: "bold",
};

class Sidebar extends Component {
  state = {
    seoMenuOpen: false,
    blogsMenuOpen: false,
    workspaceMenuOpen: false,
    user: null,
  };

  toggleMenuState(menuState) {
    this.setState((prevState) => ({
      [menuState]: !prevState[menuState],
    }));
  }

  handleMenuClick = (menuState) => {
    if (
      menuState !== "seoMenuOpen" &&
      menuState !== "blogsMenuOpen" &&
      menuState !== "workspaceMenuOpen"
    ) {
      return;
    }
    this.toggleMenuState(menuState);
  };

  componentDidMount() {
    this.fetchUserInfo();
    this.onRouteChanged();
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", () => {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", () => {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  fetchUserInfo = () => {
    // Mock API call - replace with your actual API call
    fetch("/api/getUserInfo") // Replace with your API endpoint
      .then((response) => response.json())
      .then((data) => {
        this.setState({ user: data.data }); // Set user data in state
      });
  };

  onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(this.state).forEach((i) => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: "/Go_seo", state: "seoMenuOpen" },
      { path: "/Go_seo", state: "workspaceMenuOpen" },
      { path: "/Go_blog", state: "blogsMenuOpen" },
    ];

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true });
      }
    });
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  render() {
    const { user } = this.state;

    return (
      <nav
        style={{ background: "#2F3245", minHeight: "100vh" }}
        className="sidebar sidebar-offcanvas"
        id="sidebar"
      >
        <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
          <a className="sidebar-brand brand-logo" href="index.html">
            <strong style={{ color: "#FFF" }}>BUMPPY</strong>
          </a>
          <a className="sidebar-brand brand-logo-mini" href="index.html">
            <img
              src={require("../../assets/images/logo-mini.svg")}
              alt="logo"
            />
          </a>
        </div>
        <ul className="nav">
          <li
            style={{
              ...(this.isPathActive("/Go_seo/Comapny_onboard")
                ? activeStyle
                : style),
              marginTop: "2rem",
            }}
            className={
              this.isPathActive("/Go_seo/Comapny_onboard")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link className="nav-link" to="/Go_seo/Comapny_onboard">
              <span className="p-2">
                <TiNews
                  style={{
                    color: "#FCCD2A",
                    marginRight: "1rem",
                    fontSize: "1.2rem",
                  }}
                />
              </span>
              <span style={{ color: "#FFF" }} className="menu-title">
                Onboard
              </span>
            </Link>
          </li>
          <li
            style={{
              ...(this.isPathActive("/Go_seo/Project") ? activeStyle : style),
              marginTop: ".5rem",
            }}
            className={
              this.isPathActive("/Go_seo/Project")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link className="nav-link" to="/Go_seo/Project">
              <span className="p-2">
                <IoMdAddCircle
                  style={{
                    color: "#C0EBA6",
                    marginRight: "1rem",
                    fontSize: "1.2rem",
                  }}
                />
              </span>
              <span style={{ color: "#FFF" }} className="menu-title">
                Add Project
              </span>
            </Link>
          </li>
          <li
            className={
              this.state.seoMenuOpen
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
            style={{
              ...(this.state.seoMenuOpen ? activeStyle : style),
              marginTop: ".5rem",
            }}
          >
            <div
              className={
                this.state.seoMenuOpen ? "nav-link menu-expanded" : "nav-link"
              }
              onClick={() => this.handleMenuClick("seoMenuOpen")}
              data-toggle="collapse"
            >
              <span className="p-2">
                <RiEdgeNewFill
                  style={{
                    color: "#E5D9F2",
                    marginRight: "1rem",
                    fontSize: "1.2rem",
                  }}
                />
              </span>
              <span style={{ color: "#FFF" }} className="menu-title">
                <Trans>SEO</Trans>
              </span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.seoMenuOpen}>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li
                    style={{
                      ...(this.isPathActive("/Go_seo/Dashboard")
                        ? activeStyle
                        : style),
                      marginTop: "12px",
                    }}
                    className={
                      this.isPathActive("/Go_seo/Dashboard")
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                  >
                    <Link
                      className="nav-link"
                      to="/Go_seo/Dashboard"
                      onClick={(e) => {
                        if (this.isPathActive("/Go_seo/Dashboard")) {
                          e.preventDefault(); // Prevent collapsing
                        }
                      }}
                    >
                      <span className="p-2">
                        <FaChartPie
                          style={{
                            color: "#CDC1FF",
                            marginRight: "1rem",
                            fontSize: "1.2rem",
                          }}
                        />
                      </span>
                      <span style={{ color: "#FFF" }} className="menu-title">
                        <Trans>Dashboard</Trans>
                      </span>
                    </Link>
                  </li>
                  <li
                    style={{
                      ...(this.isPathActive("/Go_seo/Seo_analyzer")
                        ? activeStyle
                        : style),
                      marginTop: "px",
                    }}
                    className={
                      this.isPathActive("/Go_seo/Seo_analyzer")
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                  >
                    <Link className="nav-link" to="/Go_seo/Seo_analyzer">
                      <span className="p-2">
                        <BiSolidReport
                          style={{
                            color: "#FADFA1",
                            marginRight: "1rem",
                            fontSize: "1.2rem",
                          }}
                        />
                      </span>
                      <span style={{ color: "#FFF" }} className="menu-title">
                        SEO Audit
                      </span>
                    </Link>
                  </li>

                  <li
                    style={
                      this.isPathActive("/Go_seo/Campaign")
                        ? activeStyle
                        : style
                    }
                    className={
                      this.isPathActive("/Go_seo/Campaign")
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                  >
                    <Link className="nav-link" to="/Go_seo/Campaign">
                      <span className="p-2">
                        <MdCampaign
                          style={{
                            color: "#98DED9",
                            marginRight: "1rem",
                            fontSize: "1.5rem",
                          }}
                        />
                      </span>
                      <span style={{ color: "#FFF" }} className="menu-title">
                        Campaign
                      </span>
                    </Link>
                  </li>
                  <li
                    style={
                      this.isPathActive("/Go_seo/Productblog")
                        ? activeStyle
                        : style
                    }
                    className={
                      this.isPathActive("/Go_seo/Productblog")
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                  >
                    <Link className="nav-link" to="/Go_seo/Productblog">
                      <span className="p-2">
                        <MdDashboard
                          style={{
                            color: "#7695FF",
                            marginRight: "1rem",
                            fontSize: "1.2rem",
                          }}
                        />
                      </span>
                      <span style={{ color: "#FFF" }} className="menu-title">
                        All Product
                        <br /> Blog
                      </span>
                    </Link>
                  </li>
                  <li
                    className={
                      this.state.workspaceMenuOpen
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                    style={{
                      ...(this.state.workspaceMenuOpen ? activeStyle : style),
                      marginTop: ".5rem",
                    }}
                  >
                    <div
                      className={
                        this.state.workspaceMenuOpen
                          ? "nav-link menu-expanded"
                          : "nav-link"
                      }
                      onClick={() => this.handleMenuClick("workspaceMenuOpen")}
                      data-toggle="collapse"
                    >
                      <span className="p-2">
                        <FcDataConfiguration
                          style={{
                            color: "Plum",
                            marginRight: "1rem",
                            fontSize: "1.2rem",
                          }}
                        />
                      </span>
                      <span style={{ color: "#FFF" }} className="menu-title">
                        <Trans>WorkSpace</Trans>
                      </span>
                      <i className="menu-arrow"></i>
                    </div>
                    <Collapse in={this.state.workspaceMenuOpen}>
                      <div>
                        <ul className="nav px-0 mx-3  flex-column sub-menu">
                          <li
                            style={{
                              ...(this.isPathActive("/Go_seo/Sitemap")
                                ? activeStyle
                                : style),
                              marginTop: "12px",
                            }}
                            className={
                              this.isPathActive("/Go_seo/Sitemap")
                                ? "nav-item menu-items active"
                                : "nav-item menu-items"
                            }
                          >
                            <Link className="nav-link" to="/Go_seo/Sitemap">
                              <span className="p-2">
                                <FaSitemap
                                  style={{
                                    color: "#F05A7E",
                                    marginRight: "1rem",
                                    fontSize: "1.2rem",
                                  }}
                                />
                              </span>
                              <span
                                style={{ color: "#FFF" }}
                                className="menu-title"
                              >
                                <Trans>Sitemap</Trans>
                              </span>
                            </Link>
                          </li>
                          <li
                            style={
                              this.isPathActive("/Go_seo/Pages")
                                ? activeStyle
                                : style
                            }
                            className={
                              this.isPathActive("/Go_seo/Pages")
                                ? "nav-item menu-items active"
                                : "nav-item menu-items"
                            }
                          >
                            <Link className="nav-link" to="/Go_seo/Pages">
                              <span className="p-2">
                                <RiPagesLine
                                  style={{
                                    color: "#F6E96B",
                                    marginRight: "1rem",
                                    fontSize: "1.2rem",
                                  }}
                                />
                              </span>
                              <span
                                style={{ color: "#FFF" }}
                                className="menu-title"
                              >
                                Pages
                              </span>
                            </Link>
                          </li>
                          <li
                            style={
                              this.isPathActive("/Go_seo/Social_links")
                                ? activeStyle
                                : style
                            }
                            className={
                              this.isPathActive("/Go_seo/Social_links")
                                ? "nav-item menu-items active"
                                : "nav-item menu-items"
                            }
                          >
                            <Link
                              className="nav-link"
                              to="/Go_seo/Social_links"
                            >
                              <span className="p-2">
                                <IoShareSocialSharp
                                  style={{
                                    color: "#6EACDA",
                                    marginRight: "1rem",
                                    fontSize: "1.2rem",
                                  }}
                                />
                              </span>
                              <span
                                style={{ color: "#FFF" }}
                                className="menu-title"
                              >
                                Social
                                <br /> Links
                              </span>
                            </Link>
                          </li>
                          <li
                            style={
                              this.isPathActive("/Go_seo/Internal_links")
                                ? activeStyle
                                : style
                            }
                            className={
                              this.isPathActive("/Go_seo/Internal_links")
                                ? "nav-item menu-items active"
                                : "nav-item menu-items"
                            }
                          >
                            <Link
                              className="nav-link"
                              to="/Go_seo/Internal_links"
                            >
                              <span className="p-2">
                                <CgInternal
                                  style={{
                                    color: "#88D66C",
                                    marginRight: "1rem",
                                    fontSize: "1.2rem",
                                  }}
                                />
                              </span>
                              <span
                                style={{ color: "#FFF" }}
                                className="menu-title"
                              >
                                Internal
                                <br /> Links
                              </span>
                            </Link>
                          </li>

                          <li
                            style={
                              this.isPathActive("/Go_seo/Robot_txt")
                                ? activeStyle
                                : style
                            }
                            className={
                              this.isPathActive("/Go_seo/Robot_txt")
                                ? "nav-item menu-items active"
                                : "nav-item menu-items"
                            }
                          >
                            <Link className="nav-link" to="/Go_seo/Robot_txt">
                              <span className="p-2">
                                <GrDocumentTxt
                                  style={{
                                    color: "Plum",
                                    marginRight: "1rem",
                                    fontSize: "1.2rem",
                                  }}
                                />
                              </span>
                              <span
                                style={{ color: "#FFF" }}
                                className="menu-title"
                              >
                                Robots.txt
                              </span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </Collapse>
                  </li>
                </ul>
              </div>
            </Collapse>
          </li>
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }
}

export default withRouter(Sidebar);
